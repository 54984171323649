import React from 'react';
import PropTypes from 'prop-types';
import { latinize } from 'Helpers/strings';

export const HighlightText = ({ text, term, caseSensitive }) => {
    if (!term) {
        return (<span>{text}</span>);
    }
    const latinTerm = latinize(term.trim());
    const regex = new RegExp(latinTerm, caseSensitive ? '' : 'i');
    const showTerm = regex.exec(text) ? regex.exec(text)[0] : term;

    return (<span>
        { text.split(regex)
            .reduce((prev, current, i) => {
                if (!i) {
                    return [current];
                }
                return prev.concat(<b key={ term + current }>{ showTerm }</b>, current);
            }, [])
        }
    </span>);
};

HighlightText.propTypes = {
    text: PropTypes.string.isRequired,
    term: PropTypes.string,
    caseSensitive: PropTypes.bool
};

HighlightText.defaultProps = {
    term: null,
    caseSensitive: false
};

export default HighlightText;
