import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import css from './LocationSection.scss';
import LocationOption from '../LocationOption/LocationOption';
import { getNearByAds } from 'Actions/categoryBrowsing';
import { TYPE_NEAR_ME } from 'Constants/tracking';
import { gpsSelector } from 'Selectors/location';

export function computeName({ location = {}, status = null, isFetching }, intl) {
    const hasLocationData = location && Object.keys(location).length;

    if (isFetching && !hasLocationData) {
        return intl.formatMessage({ id: 'fetchingLocationLabel' });
    }
    else if (status === null || status === 'prompt') {
        return intl.formatMessage({ id: 'enableLocationLabel' });
    }
    else if (status === 'rejected') {
        return intl.formatMessage({ id: 'gpsBlockedLabel' });
    }
    else if (hasLocationData) {
        const names = Array.from(new Set(map(location, 'name')));

        return names.join(', ');
    }
    return intl.formatMessage({ id: 'current_gps_location_label' });
}

export const CurrentLocation = ({ onChange, onLoading, getNearByAds, intl, gpsData }) => {
    const name = computeName(gpsData, intl);
    const onSelect = useCallback(
        () => getNearByAds(onLoading)
            .then(location => onChange(location, { location_type: TYPE_NEAR_ME }))
            .catch(() => onChange())
        , [getNearByAds, onLoading, onChange]);

    return (
        <div className={ `${css.sectionLocations} ${css.currentLocation}` }>
            <LocationOption
                name={ name }
                icon={ 'crosshairs' }
                onSelect={ onSelect }
                id={ css.currentLocationIcon }
                showLabel
                showFullText={ gpsData.status === 'rejected' }
                iconSize={ 24 }
            />
        </div>
    );
};

CurrentLocation.propTypes = {
    onChange: PropTypes.func.isRequired,
    onLoading: PropTypes.func,
    getNearByAds: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    gpsData: PropTypes.object
};

const mapStateToProps = state => ({
    gpsData: gpsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getNearByAds: callback => dispatch(getNearByAds(callback))
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(CurrentLocation);
