import React from 'react';
import PropTypes from 'prop-types';
import css from './LocationSection.scss';
import { FormattedMessage as Translation } from 'react-intl';
import LocationOption from '../LocationOption/LocationOption';
import { TYPE_SAVED_HISTORY } from 'Constants/tracking';

const RecentLocations = ({ selectedLocation = {}, recentLocations, onChange }) => {
    const recents = recentLocations
        && !!recentLocations.length
        && recentLocations.filter(location => location.id !== selectedLocation.id);

    return recents.length ? (
        <div className={ css.sectionLocations }>
            <div className={ css.sectionLocationsTitle }>
                <Translation id="recentLocations" />
            </div>
            <div>
                { recents.map((recent, key) => {
                    const onSelect = () => onChange(recent, { location_type: TYPE_SAVED_HISTORY });

                    return (
                        // eslint-disable-next-line react/jsx-no-bind
                        <LocationOption key={ key } name={ recent.name } onSelect={ onSelect } iconSize={ 24 } />
                    );
                })}
            </div>
        </div>
    ) : null;
};

RecentLocations.propTypes = {
    onChange: PropTypes.func.isRequired,
    recentLocations: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    })).isRequired,
    selectedLocation: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }).isRequired
};

export default RecentLocations;
