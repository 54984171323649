import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import classNames from 'classnames';
import css from './LocationOption.scss';
import HighlightText from 'Components/HighlightText/HighlightText';

const LocationOption = ({ name, onSelect, highlight, iconClass, icon, showLabel, showFullText, id, iconSize }) => {
    return (
        <div data-aut-id="locationItem" className={ css.locationOption } onClick={ onSelect }>
            <IconWrapper
                className={ classNames(css.icon, iconClass) }
                direction="ltr"
                icon={ icon }
                name="IconButton"
                id={ id }
                size={ iconSize }
            />
            <div className={ css.text }>
                { showLabel && <div className={ css.currentLocationLabel } ><Translation id="useCurrentLocationLabel" /></div> }
                { highlight ? <HighlightText text={ name } term={ highlight } /> : <span className={ classNames({ [css.fullText]: showFullText }) }>{ name }</span> }
            </div>
        </div>
    );
};

LocationOption.defaultProps = {
    icon: 'location',
    iconClass: '',
    showLabel: false,
    showFullText: false,
    id: '',
    name: '',
    onSelect: () => {},
    iconSize: 48
};

LocationOption.propTypes = {
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    highlight: PropTypes.string,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    id: PropTypes.string,
    showLabel: PropTypes.bool,
    showFullText: PropTypes.bool,
    iconSize: PropTypes.number
};

export default LocationOption;
