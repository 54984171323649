import React from 'react';
import { Overlay } from 'panamera-react-ui';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './GpsBlocked.APP_TARGET.scss';
import IconWrapper from 'Components/IconWrapper/IconWrapper';

export const GpsBlocked = ({ intl, onClose }) => (
    <div data-aut-id="gps-blocked-modal">
        <Overlay
            isOpened
            overlayClassName={ classnames(css.gpsBlocked) }
            contentClassName={ classnames(css.modal) }
            onClose={ onClose }
        >
            <div className={ classnames(css.close) }>
                <IconWrapper dataAutId="btnClose" onClick={ onClose } icon="cross" size={ 24 } color="blackIcons" />
            </div>
            <div className={ classnames(css.container) }>
                <div className={ classnames(css.title) }>
                    <span>
                        { intl.formatMessage({ id: 'geolocation_blocked' }) }
                    </span>
                </div>
                <div className={ classnames(css.description) }>
                    <span>
                        { intl.formatMessage({ id: 'geolocation_blocked_description' }) }
                    </span>
                </div>
            </div>
        </Overlay>
    </div>
);

GpsBlocked.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    onClose: PropTypes.func.isRequired
};

export default injectIntl(GpsBlocked);
