import React from 'react';
import PropTypes from 'prop-types';
import { css as uiCss } from 'panamera-react-ui';
import css from './TooltipMessage.scss';
import classNames from 'classnames';

const { icons } = uiCss;
const MARGIN_TOP = 5;

class ErrorMessage extends React.Component {
    static propTypes = {
        message: PropTypes.object,
        elementNode: PropTypes.object,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => {}
    }

    componentDidMount = () => document.addEventListener('mousedown', this.handleClickOutside);

    componentWillUnmount = () => document.removeEventListener('mousedown', this.handleClickOutside);

    handleClickOutside = event => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            this.props.onClose();
        }
    }

    render() {
        const { elementNode, onClose, message } = this.props;
        const style = elementNode && {
            width: elementNode.offsetWidth,
            top: elementNode.offsetHeight + elementNode.offsetTop + MARGIN_TOP,
            left: elementNode.offsetLeft
        };

        return elementNode ? (
            <div className={ css.errorMessage } style={ style } ref={ el => (this.containerRef = el) } >
                <div className={ css.content }>
                    <span
                        className={ classNames(icons.panameraIcons, icons['icon-Cross'], css.close) }
                        onClick={ onClose } />
                    { message }
                </div>
            </div>
        ) : null;
    }
}

export default ErrorMessage;

