import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { getPopularLocations } from 'Actions/locations';
import {
    popularLocationsSelector,
    isFetchingPopularSelector
} from 'Selectors/popularLocations';
import { countryLocationSelector } from 'Selectors/location';
import { withConfig } from 'HOCs/withConfig/withConfig';

const withPopularLocations = WrappedComponent => {
    class WithPopularLocations extends React.Component {
        componentDidMount = () => this.props.getPopularLocations(this.props.marketConfig);

        componentDidUpdate = () => this.props.getPopularLocations(this.props.marketConfig);

        render() {
            const { isFetchingPopular, popular } = this.props;

            return (
                <WrappedComponent
                    { ...this.props }
                    isFetchingPopularLocations={ isFetchingPopular }
                    popularLocations={ popular }
                />
            );
        }
    }

    WithPopularLocations.propTypes = {
        popular: PropTypes.array,
        isFetchingPopular: PropTypes.bool,
        dispatch: PropTypes.func,
        marketConfig: PropTypes.shape({
            get: PropTypes.func
        }),
        getPopularLocations: PropTypes.func.isRequired
    };

    WithPopularLocations.fetchData = (...args) => {
        const [dispatch, reqContext] = args;
        const { config } = reqContext;
        const promise = [(WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())];

        return Promise.all(promise).then(() => {
            return dispatch(getPopularLocations(config));
        });
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithPopularLocations.displayName = `withPopularLocations(${wrappedComponentName})`;

    const mapStateToProps = (state, { marketConfig: config }) => {
        const isCountryWithoutState = config && config.get('isCountryWithoutState');

        return {
            popular: popularLocationsSelector(state, isCountryWithoutState),
            isFetchingPopular: isFetchingPopularSelector(state, isCountryWithoutState),
            countryLocation: countryLocationSelector(state)
        };
    };

    const mapDispatchToProps = (dispatch, { marketConfig: config }) => ({
        getPopularLocations: () => dispatch(getPopularLocations(config))
    });

    const connectedComponent = compose(
        withConfig('marketConfig'),
        connect(mapStateToProps, mapDispatchToProps)
    )(WithPopularLocations);

    return hoistNonReactStatic(
        connectedComponent,
        WrappedComponent,
        { fetchData: true }
    );
};

export default withPopularLocations;
